<template>
  <div class="px-5 py-5">
    <v-col md="12"></v-col>
    <template>
      <v-dialog v-model="dialog" persistent max-width="700">
        <v-card>
          <v-card-title class="text-h5 d-flex justify-center" style="color: red;">
            <h2>{{ actionMessage == 'send' ? $t('Create Message') : $t('Edit Message') }}</h2>
          </v-card-title>
          <v-col>
            <v-text-field v-model="title" :label="$t('Message Title')">
            </v-text-field>
          </v-col>
          <v-col v-if="actionMessage == 'send'">
            <v-select v-model="type" color="#757575" :label="$t('type')" :items="typeList" item-text="name"
              item-value="value"></v-select>
          </v-col>
          <v-col v-if="type != 'broadcast' && actionMessage == 'send'">
            <v-autocomplete :items="users" item-text="name" item-value="id" v-model="user_id" @keypress="fetchUsers"
              :loading="usersLoading" :label="$t('Receiver')" @input="inputChild" return-object></v-autocomplete>
            <v-col v-if="receiversList != 'null'" v-for="(el, ix) in receiversList" :key="ix">
              <v-row>
                <v-col md="6" class="center">
                  <h4> {{ el.name }} </h4>
                </v-col>
                <v-col md="2">
                  <v-icon @click="delChild(ix)" color="red">mdi-delete</v-icon>
                </v-col>
              </v-row>

            </v-col>
          </v-col>

          <v-col>
            <div>
              {{ $t('Message') }}
              <textarea style="border: 0.5px solid black;" v-model="message" rows="5">

                        </textarea>
            </div>
            <!-- <quill-editor
                        v-model=  "message"
                        ref="myQuillEditor"
                          >
                      </quill-editor> -->
          </v-col>
          <v-col>
            <v-col md="2" style="display: flex; justify: start">
              <div>
                <input type="file" id="fileInput" class="hidden-input" multiple @change="handleFileChange" />
                <label for="fileInput" class="file-label">
                  <u class="btn">
                    <v-icon size="20" color="white">mdi-plus</v-icon>{{ $t("add attachment") }}</u>
                </label>
                <div>
                  <ul>
                    <li v-for="file in attachedFiles" :key="file.name">
                      {{ file.name }}
                    </li>
                  </ul>
                </div>
              </div>
            </v-col>
          </v-col>

          <v-col v-if="type == 'task'">


            <h2 class="d-flex justify-center">
              {{ $t('Tasks') }}
            </h2>
            <v-spacer></v-spacer>
            <v-col md="12" class="py-2">
              <table>
                <tr>
                  <th> {{ $t('Task Name') }} </th>
                  <th> {{ $t('Deadline') }}</th>
                  <th></th>
                </tr>
                <tr v-for="(el, ix) in tasks" :key="ix">
                  <td>
                    <v-text-field class="mb-6" full-width outlined dense hide-details flat color="#757575"
                      v-model="el.name" @keydown.enter="addNewTask"></v-text-field>
                  </td>
                  <!-- <v-col md="3" class="py-0"> -->
                  <td>

                    <v-row>
                      <v-col>
                        <v-text-field :label="$t('date')" :dark="$store.state.isDarkMode" outlined dense flat type="date" v-model="el.date"></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field v-model="el.time" :label="$t('Time')" :dark="$store.state.isDarkMode" outlined dense flat type="time"></v-text-field>
                      </v-col>
                    </v-row>



                  </td>


                  <td>
                    <v-icon class="mb-6" @click="deleteRowT(ix)" color="red"
                      style="border: 1px solid red; border-radius: 50%">mdi-minus</v-icon>

                  </td>
                </tr>
              </table>
            </v-col>




          </v-col>

          <v-spacer></v-spacer>
          <v-btn color="blue" text @click="cancel">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn color="blue" text @click="sendMessage">
            <span> {{ actionMessage == 'send' ? $t('Send') : $t('edit') }}</span>

          </v-btn>
          <v-spacer></v-spacer>
          <v-col></v-col>
        </v-card>
      </v-dialog>
    </template>
    <v-col md="3">
      <v-btn @click="createMessage" color="green" dark>
        + {{ $t('Create Message') }}
      </v-btn>
    </v-col>
    <v-spacer></v-spacer>
    <div>
      <template>
        <v-row>
          <v-col md="3">
            <v-text-field :label="$t('hr.time.from-date')" type="date" v-model="filters.from">
            </v-text-field>
          </v-col>
          <v-col md="3">
            <v-text-field :label="$t('hr.time.to-date')" type="date" v-model="filters.to">
            </v-text-field>
          </v-col>
          <v-col md="3">
            <v-autocomplete :items="users" item-text="name" item-value="id" v-model="filters.receiver_id"
              @keypress="fetchUsers" :loading="usersLoading" :label="$t('Receiver')"></v-autocomplete>
          </v-col>
          <v-col md="3">
            <v-select v-model="filters.type" color="#757575" :label="$t('type')" :items="typeListFilter" item-text="name"
              item-value="value"></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="4">
            <v-text-field v-model="filters.title" :label="$t('Message Title')">
            </v-text-field>
          </v-col>
          <v-col md="5">
            <v-text-field v-model="filters.message" :label="$t('Message')">
            </v-text-field>
          </v-col>


          <v-col md="1" class="py-7">
            <v-icon large color="gray" @click="clearFilter">mdi-close</v-icon>
          </v-col>
          <v-col md="1" class="py-7">
            <v-icon large color="gray" @click="getMessagesSent"> mdi-magnify </v-icon>
          </v-col>
        </v-row>

      </template>
    </div>
    <v-data-table :dark="$store.state.isDarkMode" :headers="headers" :loading="loading" :items="items">
      <template v-slot:item.actions="{ item }">

        <v-btn v-if="returnAbility('message:show')" @click="showMessage(item)" color="green" icon>
          <v-icon class="ml-1">mdi-eye</v-icon></v-btn>
        <v-btn v-if="returnAbility('message:update')" @click="editMessage(item)" color="blue" icon>
          <v-icon class="ml-1">mdi-pen</v-icon></v-btn>
        <v-btn v-if="returnAbility('message:delete')" @click="deleteMessage(item.id)" color="red" icon>
          <v-icon class="ml-1">mdi-delete</v-icon></v-btn>
      </template>
    </v-data-table>

    <v-row>
      <v-dialog width="500" v-model="delete_dialog">
        <v-card>
          <v-card-title class="d-flex justify-center">
            <h2>
              {{ $t("are you sure delete") }}
            </h2>
          </v-card-title>
          <v-card-actions class="justify-center">
            <v-btn color="green" @click="confirm_delete = true" class="white--text" depressed>{{ $t("submit") }}</v-btn>
            <v-btn color="red" class="white--text" depressed @click="delete_dialog = false">{{ $t("cancel") }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>


  </div>
</template>
  
<script>
import axios from "axios";
import i18n from '@/i18n';
import debounce from "../../../../helpers/debounce";


export default {
  components: {

  },
  data() {
    return {
      actionMessage: null,
      headers: [
        { text: i18n.t("date"), value: "date" },
        { text: i18n.t("Receiver"), value: "receiver" },
        { text: i18n.t("Message Title"), value: "title" },
        { text: i18n.t("type"), value: "type" },
        { text: "", value: "actions" },
      ],
      typeListFilter: [
        {
          name: i18n.t("Mail"),
          value: "mail",
        },
        {
          name: i18n.t("Task"),
          value: "task",
        },
        {
          name: i18n.t("Broadcast"),
          value: "broadcast",
        },
        {
          name: i18n.t("Reply"),
          value: "reply",
        },
      ],
      typeList: [
        {
          name: i18n.t("Mail"),
          value: "mail",
        },
        {
          name: i18n.t("Task"),
          value: "task",
        },
        {
          name: i18n.t("Broadcast"),
          value: "broadcast",
        },
      ],
      filters: {
        receiver_id: null,
        title: null,
        message: null,
        type: null,
        from: null,
        to: null,
      },
      idMessage: null,
      confirm_delete: false,
      tasks: [],
      attachedFiles: [],
      usersLoading: null,
      users: [],
      user_id: null,
      items: [],
      title: null,
      message: null,
      type: 'mail',
      receivers: [],
      receiversList: [],
      loading: false,
      dialog: false,
      deleteItemId: null,
      delete_dialog: false,
    };
  },
  watch: {
    confirm_delete() {
      if (this.confirm_delete == true) {
        axios.delete(`/message/${this.deleteItemId}`).then((res) => {

          if (res.status == 200) {
            this.$Notifications(
              "تم الحذف  بنجاح",
              { rtl: true, timeout: 2500 },
              "success"
            );

          }

        });
        this.getMessagesSent();
        this.delete_dialog = false;
        this.confirm_delete = false;
      }
    },
  },
  methods: {
    async deleteMessage(item) {
      this.deleteItemId = item;
      this.delete_dialog = true;
    },
    showMessage(item) {
      if (item.type == 'reply') {
        this.$router.push(
          `showMessage/${item.parent_id}`
        );
      } else {
        this.$router.push(
          `showMessage/${item.id}`
        );
      }
    },
    editMessage(item) {
      this.type = item.type;
      this.idMessage = item.id;
      this.title = item.title;
      this.message = item.message;
      for (let i = 0; i < item.tasks.length; i++) {
        this.tasks.push({
          name: item.tasks[i].name,
          date: item.tasks[i].date,
          time: item.tasks[i].time,
        });
      }


      this.dialog = true;
      this.actionMessage = 'edit';
    },
    createMessage() {
      this.dialog = true;
      this.actionMessage = 'send';
      this.addNewTask();
    },
    deleteRowT(index) {
      if (this.tasks.length == 1) return;
      this.tasks.splice(index, 1);
    },
    addNewTask(focus = true) {
      this.tasks.push({
        name: null,
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        time: `${String(new Date().getHours()).length == 1
            ? `0${new Date().getHours()}`
            : new Date().getHours()
          }:${String(new Date().getMinutes()).length == 1
            ? `0${new Date().getMinutes()}`
            : new Date().getMinutes()
          }`,
      });
    },
    delChild(i) {
      this.receiversList.splice(i, 1);
    },
    inputChild() {
      this.receiversList.push(this.user_id);

      this.user_id = null;
      this.users = null;
    },
    async fetchUsers(searchQuery) {
      debounce(async () => {
        let searchValue = searchQuery.target.value;
        this.usersLoading = true;
        if (!searchValue) return;
        try {
          const searchResult = await axios.get("/user/auto-complete", {
            params: {
              name: searchValue,
            },
          });
          this.users = searchResult.data.users;
        } catch (err) {
          console.log("err", err);
        } finally {
          this.usersLoading = false;
        }
      }, 500)();
    },
    handleFileChange(event) {
      const files = event.target.files;
      this.attachedFiles = Array.from(files);
    },
    clearFilter() {
      this.filters.message = undefined;
      this.filters.receiver_id = undefined;
      this.filters.title = undefined;
      this.filters.from = undefined;
      this.filters.to = undefined;
      this.filters.type = undefined;


      // this.page = 1;
      this.getMessagesSent();
    },
    async sendMessage() {

      let formData = new FormData();
      for (let i = 0; i < this.attachedFiles.length; i++) {
        formData.append('files[]', this.attachedFiles[i]);
      }
      for (let i = 0; i < this.receiversList.length; i++) {
        formData.append('receivers[]', this.receiversList[i].id);
      }
      for (let i = 0; i < this.tasks.length; i++) {
        formData.append(`tasks[${i}][name]`, this.tasks[i].name);
        formData.append(`tasks[${i}][dead_line]`, `${this.tasks[i].date} ${this.tasks[i].time}`);
      }
      formData.append('type', this.type);
      formData.append('title', this.title);
      formData.append('message', this.message);
      // this.receiversList.forEach((el)=>{
      //   this.receivers.push(el.id);
      // })

      if (this.actionMessage == 'send') {
        const res = await axios.post("/message", formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });


        if (res.status == 200) {
          this.$Notifications(
            "تم إرسال الرسالة بنجاح",
            { rtl: true, timeout: 2500 },
            "success"
          );

        }
      } else {
        const res = await axios.post(`/message/mail/${this.idMessage}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        if (res.status == 200) {
          this.$Notifications(
            "تم تعديل الرسالة بنجاح",
            { rtl: true, timeout: 2500 },
            "success"
          );
        }
      }
      await this.getMessagesSent();
      this.cancel();
    },
    cancel() {
      this.dialog = false;
      this.title = null;
      this.message = null;
      this.type = 'mail';
      this.tasks = [];
      this.receivers = [];
      this.receiversList = [];
      this.attachedFiles = [];
      this.actionMessage = null;
    },
    async getMessagesSent() {
      const filterTemp = {};
      Object.keys(this.filters).forEach((key) => {
        if (this.filters[key]) filterTemp[key] = this.filters[key];
      });
      this.loading = true;
      const res = await axios.get("/message/sent", {
        params: {

          ...filterTemp,
        },
      });

      const arr = Array.from(res.data.data)
      if (Array.isArray(arr)) {
        console.log(arr);
        this.items = arr.map((el) => {

          return {
            ...el,

          };
        });
      }
      console.log(this.items);

      this.loading = false;
    },
  },
  created() {
    this.getMessagesSent();

  },
};
</script>
  
<style scoped lang="scss">
td,
th {
  padding: 2px 10px;

}



textarea {
  width: 100%;
  height: 200px;
  /* Set the desired height for the textarea */
  padding: 10px;
  box-sizing: border-box;
}

.btn {
  text-decoration: none;
  height: 41px;
  color: white;
  min-width: 73px;
  padding: 0 18.2222222222px;
  font-size: 1rem;
  background-color: #4caf50 !important;
  border-color: #4caf50 !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-weight: 400;
  letter-spacing: 0;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  outline: 0;
  position: relative;
  text-decoration: none;
  text-indent: 0;
  text-transform: uppercase;
  -webkit-transition-duration: 0.28s;
  transition-duration: 0.28s;
  -webkit-transition-property: opacity, -webkit-box-shadow, -webkit-transform;
  transition-property: opacity, -webkit-box-shadow, -webkit-transform;
  transition-property: box-shadow, transform, opacity;
  transition-property: box-shadow, transform, opacity, -webkit-box-shadow,
    -webkit-transform;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}

.hidden-input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
}

.file-label {
  font-size: 20px;
  display: block;
  cursor: pointer;
}
</style>
  